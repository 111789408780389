require('bootstrap-validator');

$(document).ready(function() {
    $('#contact-form').validator();

    $('input[id="email"]').change(function() {
        if (!$(this).parents().hasClass("has-error") && $(this).val().length > 0) {
            $('input[id="phone"]').removeAttr('required');
        }
    });

    $('input[id="phone"]').change(function() {
        if (!$(this).parents().hasClass("has-error") && $(this).val().length > 0) {
            $('input[id="email"]').removeAttr('required');
        }
    });

    // count znaku v poptavce na detailu
    if ($("#dealer_message").length) {
        // textarea poznamka z vypoctem zbylych znaku
        var text_max = 500;

        $('#dealer_message').keyup(function() {
            var text_length = mb_strlen($(this).val());
            var count_remaining = text_max - text_length;
            var text_remaining = $("input[name='lang--remaining']").val();

            $('.count_message').html(count_remaining + ' ' + text_remaining);
        });

    }

    // Schovávání tooltipů na detailu po kliknutí na modal, close modal - ikony výhody
    $(function() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover'
        })

        $(".advantages__icon").click(function() {
            $('[data-toggle="tooltip"], .tooltip').tooltip("hide");
        });
    });

}); // END domready


// vypocet pro pocet znaku ve stringu dle charakteru, tzn diakrika ma nektere znaky za 2 proti normal .lenght
function mb_strlen(str) {
    var len = 0;

    for (var i = 0; i < str.length; i++) {
        len += str.charCodeAt(i) < 0 || str.charCodeAt(i) > 255 ? 2 : 1;
    }

    return len;
}

/****************
 * COMMON INIT
 ***************/

// nesmi bejt na onready kdyz se ajaxove donacitaji radky
$(document).on('click', '.row-car', function() {
    window.document.location = $(this).data("url");
});

jQuery(document).ready(function() {
    $("a.scroll").click(function() {
        var targetDiv = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(targetDiv).offset().top
        }, 'slow');
    });

    $(function() {
        $('[data-toggle="tooltip"]').tooltip()
    })

    $(function() {
        $('[data-toggle="popover"]').popover()
    })

    $('.form-top [data-toggle=collapse]').click(function() {
        $(this).find("span").toggleClass("glyphicon-chevron-down glyphicon-chevron-up"); // toggle icon
    });


    // proklik primo z menu, pokud je ale mobil tak nezadouci, kvuli dropdownu
    $('#navbar').on('hide.bs.collapse ', function() {
        $("a#pvv-link").click(function() {
            window.document.location = $(this).attr("href");
        });
    });

    /*$('.carousel').bcSwipe({ threshold: 50 })*/

    $("select").select2({
        language: "cs",
        width: '100%',
        allowClear: false,
        // minimumResultsForSearch: Infinity
    });

    $(".select2--no-search").select2({
        language: "cs",
        width: '100%',
        allowClear: false,
        minimumResultsForSearch: Infinity
    });

    $('.select2--default').select2(); // default

    // hover na dropdown menu - dela blbosti u mobilu
    /*$(".navbar .dropdown-menu").hover(function() {

      $(this).parent().css("background-color","#fff");
      $(this).parent().css("color","#000");
      //alert("hover");

    },
     function(){

      alert("pryc");
      $(this).parent().css("background-color","transparent");

    });*/
}); //end init



jQuery(document).ready(function() {
    // aktivní třídy na buttonech ve vyhledávači na hp
    $("#byFulltext").click(function() {
        if ($("#byFulltext").hasClass("active")) {} else {
            $("#byFulltext").toggleClass("active");
            $("#byParam").toggleClass("active");
        }
    })

    $("#byParam").click(function() {
        if ($("#byParam").hasClass("active")) {} else {
            $("#byParam").toggleClass("active");
            $("#byFulltext").toggleClass("active");
        }
    })



    //scroll k předanému parametru v url

    getscroll();

    function getscroll() {
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        };

        if (getUrlParameter('scrollto') != null) {
            var wheretoscroll = getUrlParameter('scrollto');

            $("html, body").animate({
                scrollTop: $("#" + wheretoscroll + "").offset().top - 0
            }, 1000);
        }
        /* za url adresou musí být ?scrollto=scrollhere -> scrollto je název proměnné, scrollhere je id alementu ke kterému scrollujeme */
    }
});